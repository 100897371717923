<template>
  <div class="pl-8 pr-8">
    <div class="mb-xl-margin-file-sign">
      <v-row class="mt-10">
        <v-col class="text-left navidad-text" cols="6">
          <h1 class="reference__title">Reporte de proceso</h1>
        </v-col>
      </v-row>
      <v-card outlined class="reference__card px-8 mt-8" elevation="0">
        <!-- Filtros -->
        <v-row class="mt-2">
          <v-col cols="6">
            <v-autocomplete
              :items="costCenters"
              label="Centro de costos"
              v-model="costCentersId"
              item-text="cc"
              item-value="cc"
              multiple
              @change="handleCostCenterChange"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              :items="subCostCenters"
              label="Sub Centro de Costos"
              v-model="subCostCentersId"
              item-text="cod_scc"
              item-value="cod_scc"
              multiple
              @change="handleSubCostCenterChange"
              :disabled="subCostCenters.length === 0"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              :items="campaign"
              label="Campaña"
              v-model="campaignId"
              item-text="campaign"
              item-value="campaign"
              multiple
              @change="handleCampaing"
              :disabled="campaign.length === 0"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="150"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Seleccionar rango de fecha"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDate"
                range
                :max="maxDate"
                :min="minDate"
              >
                <v-btn
                  text
                  color="primary"
                  @click="(menu = false), (selectedDate = null)"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="(menu = false), searchPerDate()"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              prepend-inner-icon="mdi-account-outline"
              color="#466be3"
              :items="listUserAll"
              v-model="listUserAllId"
              item-text="full_name"
              item-value="document_number"
              label="Nombre - Número de cédula - Usuario de red"
              multiple
              @change="handleUser"
            >
              <template v-slot:selection="data">
                <v-chip small dark v-bind="data.attrs" color="#466be3">
                  {{ data.item.full_name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="pl-0 pr-0" cols="12">
            <v-data-table
              :loading="loading.table"
              :items-per-page="15"
              :headers="headers"
              :items="listProcessDisciplinary"
              :footer-props="{ itemsPerPageOptions: [5, 10, 15] }"
              class="elevation-1 candidate-list__table"
            >
              <template v-slot:no-results>
                <v-alert
                  text
                  dense
                  type="info"
                  class="mt-3 forms__alert-completed"
                >
                  <b>No se encontraron resultados.</b>
                </v-alert>
              </template>
              <template class="pl-0 pr-0" v-slot:no-data>
                <v-alert
                  text
                  dense
                  type="info"
                  class="mt-3 forms__alert-completed"
                >
                  <b>No se encontró información.</b>
                </v-alert>
              </template>
              <template v-slot:progress>
                <v-progress-linear
                  color="#214484"
                  :height="5"
                  indeterminate
                  stream
                ></v-progress-linear>
              </template>

              <template v-slot:item.motivo="{ item }">
                <v-tooltip max-width="300" color="#000" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <p class="mb-0 mt-2 text-1-1-lines">
                        <strong> {{ item.motivo.slice(0, 50) }}{{ item.motivo.length > 50 ? '...' : '' }} </strong>
                      </p>
                    </div>
                  </template>
                  <span>
                    <strong>Motivo</strong><br />
                    {{ item.motivo }}
                  </span>
                </v-tooltip>
              </template>

              <template v-slot:item.status_id="{ item }">
                <v-chip
                  v-if="item.status_id"
                  :color="getColorStatus( getNameStatus(item.status_id))"
                  dark
                  small
                >
                  {{ getNameStatus(item.status_id) }}
                </v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-hover v-slot="{ hover }">
                  <v-btn fab icon small @click="goDeteils(item)">
                    <v-icon
                      :style="{
                        color: hover ? '#324CA1' : '#466BE3',
                      }"
                      small
                    >
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  mixins: [color],
  data() {
    return {
      director: false,
      listVacancies: [],
      vacancies: null,
      info: {
        post_title: "",
        tipo_de_documento: "",
        numero_de_documento: "",
        fecha_expedicion: "",
        correo_electronico: "",
        telefono: "",
        fecha_nacimiento: "",
        hoja_de_vida: "",
        primera_vez_en_brm: "",
        post_date: "",
        tratamiento_de_datos: "",
      },
      listProcessDisciplinary: [],
      search: 0,
      loading: {
        table: false,
      },
      headers: [
        {
          text: "Nombre",
          value: "full_name",
          align: "start",
          class: "candidate-list__header",
          sortable: false,
        },
        {
          text: "Número de documento",
          value: "document_number",
          align: "center",
          class: "candidate-list__header",
          sortable: false,
        },
        {
          text: "Fecha de creación",
          value: "fecha",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "Hora",
          value: "hora",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "Motivo",
          value: "motivo",
          align: "center",
          class: "candidate-list__header",
          sortable: false,
        },
        {
          text: "Estado del proceso",
          value: "status_id",
          align: "center",
          class: "candidate-list__header",
          sortable: false,

        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          class: "candidate-list__header",
          sortable: false,
        },
      ],
      modal: {
        info: false,
      },
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loading: {
        table: false,
      },
      costCenters: [],
      costCentersId: [],
      subCostCenters: [],
      subCostCentersId: [],
      campaign: [],
      campaignId: [],
      selectedDate: [
        new Date(new Date().setDate(new Date().getDate() - 365))
          .toISOString()
          .substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      listUserAll: [],
      listUserAllId: [],
      menu: false,
      minDate: new Date(new Date().setDate(new Date().getDate() - 365))
        .toISOString()
        .substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    goDeteils(data) {
      localStorage.setItem("infoDisciplinary", JSON.stringify(data));
      this.$router.push({
        name: "disciplinaryDetails",
        params: { id: data.id_proceso },
      });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    searchData() {
      var data = {
        cc: this.mapingDataCc(),
        scc: this.mapingDataScc(),
        campain: this.mapingDataCampaing(),
        cedula: this.listUserAllId,
        director: this.director,
        user_id: this.currentUser.id,
        fecha_inicio: this.selectedDate[0],
        fecha_final: this.selectedDate[1],
      };
      this.loadingAll = true;
      Api.disciplinaryProcesses()
        .listProcess(this.token, data)
        .then((res) => {
          this.listProcessDisciplinary = res.data.data;
          // console.log(res)
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingAll = false;
        });
    },
    listCostCenter() {
      var data = {
        director: this.director,
        user_id: this.currentUser.id,
      };
      Api.disciplinaryProcesses()
        .listCostCenter(this.token, data)
        .then((res) => {
          this.costCenters = [{ cc: "Todos" }, ...res.data.data];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.searchData();
        });
    },
    listSubCostCenter() {
      var data = {
        cc: this.mapingDataCc(),
        director: this.director,
        user_id: this.currentUser.id,
      };
      if (this.costCentersId.length === 0) {
        this.subCostCenters = [];
        this.campaign = [];
        this.searchData();
      } else {
        Api.disciplinaryProcesses()
          .listSubCostCenter(this.token, data)
          .then((res) => {
            this.subCostCenters = [{ cod_scc: "Todos" }, ...res.data.data];
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.searchData();
          });
      }
    },
    listCampaign() {
      if (this.subCostCentersId.length == 0) {
        this.campaign = [];
        this.searchData();
      } else {
        var data = {
          cc: this.mapingDataCc(),
          scc: this.mapingDataScc(),
          director: this.director,
          user_id: this.currentUser.id,
        };
        Api.disciplinaryProcesses()
          .listCampaign(this.token, data)
          .then((res) => {
            this.campaign = res.data.data.filter(
              (item) => item.campaign !== null
            );

            if (
              !this.campaignId.includes("Todos") &&
              this.campaign.length != 0
            ) {
              this.campaign.unshift({ campaign: "Todos" });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.searchData();
          });
      }
    },
    listUsers() {
      let currentUser = this.$store.getters.getUser;
      this.currentUser = currentUser;

      this.director = this.containsDirectorOrCoordinador(
        this.$store.getters.getUser.profile.name
      );
      var data = {
        director: this.director,
        user_id: this.currentUser.id,
      };

      Api.disciplinaryProcesses()
        .listUsers(this.token, data)
        .then((res) => {
          this.listUserAll = res.data.data;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCostCenterChange() {
      if (this.costCentersId.includes("Todos")) {
        this.costCentersId = ["Todos"];
      }
      this.listSubCostCenter();
    },
    handleSubCostCenterChange() {
      if (this.subCostCentersId.includes("Todos")) {
        this.subCostCentersId = ["Todos"];
      }
      this.listCampaign();
    },
    handleCampaing() {
      if (this.campaignId.includes("Todos")) {
        this.campaignId = ["Todos"];
      }
    },
    mapingDataCc() {
      const isTodosSelected = this.costCentersId.includes("Todos");
      if (isTodosSelected) {
        return this.costCenters
          .map((costCenter) => costCenter.cc)
          .filter((costCenter) => costCenter !== "Todos");
      } else {
        return this.costCentersId;
      }
    },
    mapingDataScc() {
      const isTodosSelected = this.subCostCentersId.includes("Todos");
      if (isTodosSelected) {
        return this.subCostCenters
          .map((subCostCenter) => subCostCenter.cod_scc)
          .filter((subCostCenter) => subCostCenter !== "Todos");
      } else {
        return this.subCostCentersId;
      }
    },
    mapingDataCampaing() {
      const isTodosSelected = this.campaignId.includes("Todos");
      if (isTodosSelected) {
        return this.campaign.map((campaign) => campaign.campaign);
      } else {
        return this.campaignId;
      }
    },
    containsDirectorOrCoordinador(text) {
      const regex = /director|coordinador/i; // The 'i' indicates that the search should be case-insensitive
      return regex.test(text);
    },
    searchPerDate() {
      this.searchData();
    },
    handleUser() {
      this.searchData();
    },
    getNameStatus(status) {
      switch (status) {
        case 1:
          return "Citado";
        case 8:
          return "En proceso";
        case 4:
          return "Finalizado";
        default:
          return "Desconocido";
      }
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
  },
  computed: {
    dateRangeText() {
      return this.selectedDate.join(" ~ ");
    },
  },
  created() {
    this.getToken();
    setTimeout(() => {
      this.listUsers();
      this.listCostCenter();
    }, 3000);
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.navidad-text {
  display: flex;
}

.reference__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}

tr,
th,
span .reference__table .text-start .sortable {
  font-size: 16px !important;
}

.reference__card {
  border-radius: 15px !important;
}

.reference__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.references__header {
  color: #353535 !important;
}
</style>
